<i18n locale="en" lang="yaml">
page.title : "{name} :: Update Company Information"
page.description : "Update company information for {name}"
update_company.success : "Update Company Information \"{name}\" successful"
</i18n>
<i18n locale="th" lang="yaml" >
page.title : "{name} :: แก้ไขข้อมูลบริษัท"
page.description : "แก้ไขข้อมูลของบริษัท {name} ในระบบ"
update_company.success : "ทำการแก้ไขข้อมูลบริษัท {name} ในระบบเรียบร้อย"
</i18n>

<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.title',{ name: company.name })"
			:description="$t('page.description',{ name: company.name })"/>

		<a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
			<CompanyForm
				ref="formRef"
				mode="update"
				:loading="loading"
				@submit="handleSubmit">
				<template v-slot:submitSlot="{ formLoading }">
					<a-button
						:disabled="formLoading"
						icon="left"
						size="large"
						:style="{ 'margin-right': '4px' }"
						@click="goBack">
						{{$t('common.back')}}
					</a-button>
					<a-button
						:loading="formLoading"
						type="info"
						html-type="submit"
						class="btn-submit"
						icon="edit"
						size="large">
						{{$t('common.update')}}
					</a-button>
				</template>
			</CompanyForm>
		</a-card>
	</div>
</template>

<script>
import PageMixin from '@mixins/PageMixin.vue'
import CompanyForm from '@components/company/CompanyForm.vue'
import axios from "axios"
import { mapActions } from 'vuex'
export default {
	page() {
		return {
			title: this.$t('page.title',{name:this.company.name})
		}
	} ,
	components: {
		CompanyForm
	} ,
	mixins: [PageMixin],
	data(){
		return {
			company : {} ,
			loading : false,
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		...mapActions('auth',['updateCurrentUserCompany']) ,
		fetchData() {
			const companyId = this.$route.params.id;
			if (!companyId)
				return

			this.showPageLoading(true)
			this.clearBreadcrumbParams('companyName')
			axios.get("/api/companies/"+companyId).then((response)=>{
				this.company = response.data.data.company;
				this.$refs.formRef.updateCompanyForm(this.company)

				this.addBreadcrumbParams({companyName : this.company.name})
			}).catch((error) => {
				this.fetchError(error);
			}).finally(()=>{
				this.hidePageLoading()
			})
		} ,
		handleSubmit(formData) {
			this.loading = true
			axios.put("/api/companies/"+this.company.id,formData).then((response) => {
				const newCompany = response.data.data.company
				this.updateCurrentUserCompany(newCompany);
				this.$router.push({
					name : 'company/view',
					params : {id : newCompany.id}
				});
				this.$message.success(this.$t('update_company.success',{"name" : newCompany.name}))
			}).catch((error) => {
				this.$refs.formRef.formErrors(error)
			}).finally(()=>{
				this.loading = false;
			});
		} ,
		goBack() {
			this.$open({name:'company/view',params : {id : this.company.id}})
		}
	} ,
}
</script>
